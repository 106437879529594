import React, { useEffect, useState } from 'react'
import { Toaster, toast } from 'sonner';
import { getAllUsersInOrg, getOrgData, switchOrgStatus } from '../../api/orgApi'
import ComponentLoader from '../ComponentLoader'
import { createUser, deleteUser, userRoleChange } from '../../api/userApi'
import Modal from 'react-modal';

const ManageOrg = ({ orgSlug = null, deleteOrgFunc }) => {
  const [AllUsers, setAllUsers] = useState(null)
  const [orgData, setOrgData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isModal, setisModal] = useState(false)
  const [email, setemail] = useState('')
  const [firstName, setfirstName] = useState('')
  const [lastName, setlastName] = useState('')
  const [role, setrole] = useState('user')
  const [confirmationModal, setConfirmationModal] = useState(false)
  let [ModalType, setModalType] = useState(null)
  let [removeUserId, setremoveUserId] = useState(null)

  // Add state to track editing
  const [editingEmail, setEditingEmail] = useState(null);
  let [newRole, setNewRole] = useState("user")



  // Edit button click handler
  const handleEditClick = (userId) => {
    setEditingEmail(userId);
  }

  // Cancel button click handler 
  const handleCancelClick = () => {
    setEditingEmail(null);
  }

  // Handle save/update after selecting new role
  const handleSelectClick = (newRoleChanged) => {
    newRole = newRoleChanged;
    setNewRole(newRoleChanged);
  }

  const roleChangeFunction = async (userData) => {
    return new Promise(async (resolve, reject) => {
      try {
        // Call handleLogin function and wait for its result
        const isSuccess = await userRoleChange(userData);

        if (isSuccess.status === 200 || isSuccess.status === 201) {
          resolve('User Role Changed Successfully!'); // Resolve with a success message
          await getAllUsers()
        } else {
          reject(new Error(isSuccess.statusText)); // Reject with an error if login fails
        }
      } catch (error) {
        reject(error); // Reject with the caught error if any error occurs
      }
    });
  }

  const removeUserFunction = async (userId) => {
    return new Promise(async (resolve, reject) => {
      try {
        // Call handleLogin function and wait for its result
        const isSuccess = await deleteUser({ userId: userId });

        if (isSuccess.status === 200 || isSuccess.status === 201) {
          resolve('Login successful'); // Resolve with a success message
          await getAllUsers()
        } else {
          reject(new Error(isSuccess.statusText)); // Reject with an error if login fails
        }
      } catch (error) {
        reject(error); // Reject with the caught error if any error occurs
      }
    });
  }

  const DeleteOrgFuncCall = async (e) => {
    // Create a wrapper function to call your promise function
    const fetchSonner = () => {
      return deleteOrgFunction(orgSlug.split('.')[1])
    };

    // Use the wrapper function with toast.promise
    toast.promise(fetchSonner, {
      loading: 'Loading...', // This message will be shown while waiting for the promise to resolve
      success: () => {
        setisModal(false)
        return `successfully deleted org`; // This message will be shown when the promise resolves successfully
      },
      error: (e) => {
        if (e.response?.data?.additionalErrorDetails?.occuredAt) {
          return e.response?.data?.additionalErrorDetails?.occuredAt;
        } else if (e.response?.data?.message) {
          return e.response?.data?.message
        } else {
          return 'error while deleting org'
        }
      },

    });
  }

  const removeUserFuncCall = async (e) => {
    // Create a wrapper function to call your promise function
    const fetchSonner = () => {
      return removeUserFunction(removeUserId)
    };

    // Use the wrapper function with toast.promise
    toast.promise(fetchSonner, {
      loading: 'Loading...', // This message will be shown while waiting for the promise to resolve
      success: () => {
        return `successfully deleted user!`; // This message will be shown when the promise resolves successfully
      },
      error: 'error while deleting user!', // This message will be shown if the promise rejects with an error
    });
  }

  useEffect(() => {
    getAllUsers()
    getOrgDataFuntion()
  }, [])

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const inviteUser = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const userData = {
          email: email,
          firstName: firstName,
          lastName: lastName,
          role: role,
          orgSlug: orgSlug
        }
        // Call handleLogin function and wait for its result
        const isSuccess = await createUser(userData);

        if (isSuccess.status === 200 || isSuccess.status === 201) {
          resolve('Created successfully');
          getAllUsers()
        } else {
          reject(new Error(isSuccess.statusText));
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  const deactiveOrgFunc = async () => {
    try {
      setIsLoading(true)
      if (orgSlug) {
        const allUsers = await switchOrgStatus(orgSlug)
        if (allUsers.status === 200 || allUsers.status === 201) {
          await getOrgDataFuntion()
          setIsLoading(false)
        }
      }
    }
    catch (error) {
      toast.error(error.additionalErrorDetails.occuredAt)
      setIsLoading(false)
    }
  }

  const getAllUsers = async () => {
    try {
      if (orgSlug) {
        const allUsers = await getAllUsersInOrg({ 'orgSlug': orgSlug })
        if (allUsers.status === 200 || allUsers.status === 201) {
          setAllUsers(allUsers.data.data)
          setIsLoading(false)
        }
      }
    }
    catch (error) {
      toast.error(error.message)
    }
  }

  const getOrgDataFuntion = async () => {
    try {
      if (orgSlug) {
        const OrgDataApi = await getOrgData(orgSlug)
        if (OrgDataApi.status === 200 || OrgDataApi.status === 201) {
          setOrgData(OrgDataApi.data.data)
          setIsLoading(false)
        }
      }
    }
    catch (error) {
      toast.error(error.message)
    }
  }

  const deleteOrgFunction = async (orgName) => {
    return new Promise(async (resolve, reject) => {
      try {
        const isSuccess = await deleteOrgFunc(orgName);

        if (isSuccess === true) {
          resolve('Deleted successfully');
        } else {
          return reject(isSuccess)
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  const closeModalFunction = async (modalType) => {
    if (modalType === 'newUser') {
      setemail(null)
      setfirstName(null)
      setlastName(null)
      setrole('user')
      setisModal(false)
    }
  }

  return (
    orgSlug ?
      <>
        {isLoading ?
          <>
            <ComponentLoader />
          </> :
          <>
            <div className='d-flex flex-row justify-content-center '>
              <Toaster position="top-right" richColors />
              <div className='w-75'>
                <button onClick={() => setisModal(true)} className='btn btn-primary btn-md mb-3'>Invite New User</button>
                <table style={{ width: "90%" }}>
                  <thead>
                    <tr>
                      <th>User Name</th>
                      <th>Email</th>
                      <th>Role</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {AllUsers && AllUsers.length ? AllUsers.map(user => (
                      <tr key={user?.uid}>
                        <td>{user?.displayName}</td>
                        <td>{user?.email}</td>
                        <td>{editingEmail === user.email ? (
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <select style={{ minWidth: "70px" }} className="form-control" value={newRole} onChange={e => handleSelectClick(e.target.value)}>
                              <option value="user">user</option>
                              <option value="admin">admin</option>
                            </select><button className='btn btn-primary btn-sm ms-2' onClick={(e) => {
                              e.preventDefault()

                              if (newRole === user?.userRole) {
                                toast.error('Please select a new role')
                                return;
                              }

                              const updatedUser = {
                                "email": editingEmail,
                                "role": newRole
                              }
                              // Create a wrapper function to call your promise function
                              const fetchSonner = () => {
                                return roleChangeFunction(updatedUser)
                              };

                              // Use the wrapper function with toast.promise
                              toast.promise(fetchSonner, {
                                loading: 'Loading...', // This message will be shown while waiting for the promise to resolve
                                success: () => {
                                  setEditingEmail(null);
                                  setNewRole("user")
                                  return `successfully changed user role!`; // This message will be shown when the promise resolves successfully

                                },
                                error: 'error while changing user role!', // This message will be shown if the promise rejects with an error
                              });
                            }}>Save</button><button className='btn btn-primary btn-sm ms-1' onClick={handleCancelClick}>Cancel</button></div>
                        ) : (
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ width: '80px' }}>{user.userRole}</span>
                            {user.userRole !== "superuser" &&
                              <button className='btn btn-primary btn-sm ms-2' onClick={() => handleEditClick(user.email)}>
                                Edit
                              </button>}
                          </div>

                        )}</td>
                        <td><button className='btn btn-primary btn-md' onClick={() => {
                          ModalType = 'removeUser'
                          setModalType('removeUser')
                          removeUserId = user?.uid
                          setremoveUserId(user?.uid)
                          setConfirmationModal(true)
                        }}>Remove User</button></td>
                      </tr>
                    )) : ""}
                  </tbody>
                </table>
              </div>
              <div className='w-25 d-flex flex-column'>
                <div className='d-flex justify-content-center align-items-center fw-bold mb-4 mt-2'>OrgName: {orgData?.name}</div>
                <div className='mb-5' style={{ padding: '40px', border: '1px solid #000', textAlign: "center" }}>
                  Org Status: {orgData?.status === 'active' ? "Active" : "InActive"}
                </div>
                <button onClick={() => {
                  ModalType = 'deactiveOrg'
                  setModalType('deactiveOrg')
                  setConfirmationModal(true)
                }} className='btn  error mb-2 btn-md'>{orgData?.status === 'active' ? 'Deactivate' : 'Activate'} Org</button>
                <button onClick={() => {
                  ModalType = 'deleteOrg'
                  setModalType('deleteOrg')
                  setConfirmationModal(true)
                }} className='btn  error mb-3 btn-md'>Delete Org</button>
              </div>
            </div>
            <Modal
              isOpen={isModal}
              onAfterOpen={() => console.log('onAfterOpen')}
              onRequestClose={() => closeModalFunction("newUser")}
              style={customStyles}
              contentLabel="New User Modal"
            >
              <div style={{ width: '500px', padding: '80px' }}>
                <div className='d-flex justify-content-around align-items-center mb-3'>
                  <h2>Invite New User</h2>
                </div>
                <div className="form-group mb-3">
                  <input value={email} onChange={(e) => setemail(e.target.value)} type="email" className=" form-control" placeholder="Email" />
                </div>
                <div className="form-group mb-3">
                  <input value={firstName} onChange={(e) => setfirstName(e.target.value)} type="fname" className=" form-control" placeholder="First Name" />
                </div>
                <div className="form-group mb-3">
                  <input value={lastName} onChange={(e) => setlastName(e.target.value)} type="lname" className=" form-control" placeholder="Last Name" />
                </div>

                <div className="form-group mb-3">
                  <select value={role} onChange={(e) => setrole(e.target.value)} className='form-control' id="">
                    <option value="admin">Admin</option>
                    <option value="user">User</option>
                  </select>
                </div>

                <div className="form-group mb-3">
                  <input value={orgSlug} type="lname" className=" form-control" disabled placeholder="orgSlug" />
                </div>

                <div className='d-flex justify-content-around align-items-center'>
                <button className='btn me-2 mt-2 w-100 btn-primary btn-md' onClick={async (e) => {
                  e.preventDefault()
                  // Create a wrapper function to call your promise function
                  const fetchSonner = () => {
                    return inviteUser()
                  };

                  // Use the wrapper function with toast.promise
                  toast.promise(fetchSonner, {
                    loading: 'Loading...', // This message will be shown while waiting for the promise to resolve
                    success: () => {
                      setisModal(false)
                      return `successfully invited user`; // This message will be shown when the promise resolves successfully
                    },
                    error: (e) => {
                      if (e.response.data.message.message) {
                        return e.response.data.message.message;
                      } else if (e.response.data.message) {
                        return e.response.data.message
                      } else {
                        return 'error while inviting user' // This message will be shown if the promise rejects with an error
                      }
                    },

                  });
                }} >Invite</button>
                 <button onClick={() => closeModalFunction("newUser")} className='btn mt-2 w-100 btn-primary btn-md'  >Cancel</button>
                </div>
              </div>
            </Modal>

            <Modal
              isOpen={confirmationModal}
              onAfterOpen={() => console.log('onAfterOpen')}
              onRequestClose={() => setConfirmationModal(false)}
              style={customStyles}
              contentLabel="New User Modal"
            >
              <div style={{ width: '500px', padding: '45px' }}>
                <div className='d-flex justify-content-around align-items-center mb-3'>
                  {ModalType === 'removeUser' && (
                    <h3>Are you sure you want to remove this user?</h3>
                  )}
                  {ModalType === 'deleteOrg' && (
                    <h3>Are you sure you want to delete this org?</h3>
                  )}
                  {ModalType === 'deactiveOrg' && (
                    <h3>Are you sure you want to {orgData?.status === 'active' ? 'deactivate' : 'activate'} this org?</h3>
                  )}
                </div>


                <div className='d-flex justify-content-around align-items-center'>
                  <button className='btn mt-2 me-2 w-100 btn-primary btn-md' onClick={async () => {
                    if (ModalType === 'removeUser') {
                      await removeUserFuncCall()
                    }
                    if (ModalType === 'deleteOrg') {
                      await DeleteOrgFuncCall()
                    }
                    if (ModalType === 'deactiveOrg') {
                      await deactiveOrgFunc()
                    }
                    setConfirmationModal(false)
                  }} >Confirm</button>
                  <button className='btn mt-2 w-100 btn-primary btn-md' onClick={async () => setConfirmationModal(false)} >Cancel</button>
                </div>
              </div>
            </Modal>
          </>
        }
      </>
      :
      <div className='d-flex flex-column justify-content-center align-items-center' >
        <h1>No Org Selected</h1>
      </div>
  )
}

export default ManageOrg
import React, { useContext, useRef, useState } from 'react'
import './styles.css';
import { UserContext } from '../contexts/userContext';
import { Toaster, toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import ReactPasswordToggleIcon from 'react-password-toggle-icon';

function SignIn() {

  const context = useContext(UserContext);

  let inputRef = useRef();
  const showIcon = () => <i class="fa fa-eye" aria-hidden="true"></i>;
  const hideIcon = () => <i class="fa fa-eye-slash" aria-hidden="true"></i>

  const { handleLogin } = context;
  const navigate = useNavigate();
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")


  const loginUser = () => {
    return new Promise(async (resolve, reject) => {
      try {
        // Call handleLogin function and wait for its result
        const isSuccess = await handleLogin({
          email: email,
          password: password
        });

        if (isSuccess === true) {
          resolve('Login successful'); // Resolve with a success message
        } else {
          reject(isSuccess); // Reject with an error if login fails
        }
      } catch (error) {
        reject(error); // Reject with the caught error if any error occurs
      }
    });
  }


  return (
    <div className="container d-flex align-items-center justify-content-center min-vh-100 text-center w-100">
      <Toaster position="top-right" richColors />
      <form className=''>
        <h2 className='mb-3'>Sign In</h2>
        <div className="form-group mb-3">
          <input type="email" className=" form-control" value={email} placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
        </div>

        <div className="form-group mb-3">

          <div className="col " style={{ position: "relative", display: "block" }}>
            <input ref={inputRef} type="password" className=" form-control" value={password} placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
            <ReactPasswordToggleIcon
              inputRef={inputRef}
              showIcon={showIcon}
              hideIcon={hideIcon}
            />
          </div>
        </div>

        <button className='btn btn-primary btn-lg' onClick={async (e) => {
          e.preventDefault()
          // Create a wrapper function to call your promise function
          const fetchSonner = () => {
            return loginUser()
          };

          // Use the wrapper function with toast.promise
          toast.promise(fetchSonner, {
            loading: 'Loading...', // This message will be shown while waiting for the promise to resolve
            success: () => {
              return `successfully logged in`; // This message will be shown when the promise resolves successfully
            },
            error: (e) => {
              if (e?.response?.data?.details?.length) {
                return e?.response?.data?.details[0]?.message
              }
              if (e?.response?.data?.message) {
                return e?.response?.data?.message?.code
              }
              return 'error! please check your credentials.'
            }, // This message will be shown if the promise rejects with an error
          });
        }}>Sign In</button>
        <button className='btn btn-primary ms-2 btn-lg' onClick={() => navigate('/reset')}>Forgot Password</button>
      </form>
    </div>
  )
}

export default SignIn
import React, { useContext, useEffect, useState } from 'react';
import './managestyles.css';
import { UserContext } from '../contexts/userContext';
import Loader from './Loader';
import Header from './Header';
import { ChangePassword } from './AccountSettings/ChangePassword';

const AccountSettings = () => {

    const context = useContext(UserContext);

    const { user, handleLogout } = context;
    const [selectedOrg, setselectedOrg] = useState(null)

    useEffect(() => {
        setTimeout(() => {
            setIsLoader(false)
        }, 1000);
    }, [])


    const [isLoader, setIsLoader] = useState(true)
    const [activeTab, setActiveTab] = useState(1);

    const [showMenu, setShowMenu] = useState(false);

    return (
        isLoader ? <Loader /> :
            <div className="container">
                <Header  user={user} handleLogout={handleLogout} showMenu={showMenu} setShowMenu={setShowMenu} title={"Account Settings"} />
                {user?.userRole !== 'admin' && user?.userRole !== 'superuser' ?
                    <div className='error-message'>
                        <h1>You are not authorized to view this page</h1>
                    </div>
                    :
                    <div>
                        <div className="content">
                            <div className="sidebar" style={{ textAlign: "center" }}>
                                {/* <div onClick={() => setActiveTab(1)} className={`tab ${activeTab === 1 ? "active" : ""}`}>Add User</div> */}
                                <div className={`tab active`}>Change Password</div>
                            </div>
                            <div className="main">
                                {/* {activeTab === 1 && <InviteUser />} */}
                                <ChangePassword/>
                            </div>
                        </div>
                    </div>}
            </div>
    )
}

export default AccountSettings
import './App.css';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react'; // Import useContext
import { UserContext } from '../src/contexts/userContext'; // Import UserContext
import Signup from './components/SignUp';
import Signin from './components/SignIn';
import ManageOrg from './components/ManageOrg';
import ForgetPass from './components/ForgetPass';
import Loader from './components/Loader';
import { ChangePass } from './components/ChangePass';
import AccountSettings from './components/AccountSettings';

function App() {
  // Access the context value using useContext
  const { accessToken, Loading, user } = useContext(UserContext); // You can access accessToken or any other value you need

  // Initialize the signedIn state based on the presence of accessToken
  const [signedIn, setSignedIn] = useState(null); // Convert to boolean


  useEffect(() => {
    if (accessToken) {
      setSignedIn(true);
    } else {
      setSignedIn(false);
    }
  }, [accessToken])


  return (
    <div className="App">
      {Loading ? <>
        <Loader />
      </> :

        <BrowserRouter>
          <Routes>
            {signedIn ? (
              user && user?.firstLogin === false ?
                <Route path="/" element={<ChangePass />} /> :
                <>
                  <Route path="/" element={<ManageOrg />} />
                  <Route path="/account-settings" element={<AccountSettings />} />
                </>
            ) : (
              <>
                <Route path="/signup" element={<Signup />} />
                <Route path="/" element={<Signin />} />
                <Route path="/reset" element={<ForgetPass />} />
              </>
            )}
          </Routes>
        </BrowserRouter>}
    </div>
  );
}

export default App;

import React, { useRef, useState } from 'react';
import './styles.css';
import { signup } from '../api/userApi';
import { Toaster, toast } from 'sonner';
import ReactPasswordToggleIcon from 'react-password-toggle-icon';

function SignUp() {

  let inputRef = useRef();
  const showIcon = () => <i class="fa fa-eye" aria-hidden="true"></i>;
  const hideIcon = () => <i class="fa fa-eye-slash" aria-hidden="true"></i>

  const signupUser = () => {
    return new Promise(async (resolve, reject) => {
      try {
        // Call handleLogin function and wait for its result
        const response = await signup({
          firstName: fName,
          lastName: lName,
          email: email,
          password: password,
        });

        if (response.status === 201 || response.status === 200) {
          resolve('Login successful'); // Resolve with a success message
          window.location.href = "/";
        } else {
          reject(new Error(response.data.message)); // Reject with an error if login fails
        }
      } catch (error) {
        reject(error); // Reject with the caught error if any error occurs
      }
    });
  }

  const [fName, setfName] = useState("")
  const [lName, setlName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  return (
    <div className="container d-flex align-items-center justify-content-center min-vh-100 text-center w-100">
      <Toaster position="top-right" richColors />
      <form className=''>
        <h2 className='mb-3'>Sign Up</h2>
        <div className="form-group mb-3">
          <input type="fname" className=" form-control" value={fName} placeholder="First Name" onChange={(e) => setfName(e.target.value)} />
        </div>
        <div className="form-group mb-3">
          <input type="lname" className=" form-control" value={lName} placeholder="Last Name" onChange={(e) => setlName(e.target.value)} />
        </div>
        <div className="form-group mb-3">
          <input type="email" className=" form-control" value={email} placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
        </div>

        <div className="form-group mb-3">
          <div className="col " style={{ position: "relative", display: "block" }}>
            <input ref={inputRef} type="password" className=" form-control" value={password} placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
            <ReactPasswordToggleIcon
              inputRef={inputRef}
              showIcon={showIcon}
              hideIcon={hideIcon}
            />
          </div>
        </div>

        <button className='btn btn-primary btn-lg' onClick={async (e) => {
          e.preventDefault()
          // Create a wrapper function to call your promise function
          const fetchSonner = () => {
            return signupUser()
          };

          // Use the wrapper function with toast.promise
          toast.promise(fetchSonner, {
            loading: 'Loading...', // This message will be shown while waiting for the promise to resolve
            success: () => {
              return `successfully signed up! You are not a part of any org. Please contact your admin to add to add you to an org`; // This message will be shown when the promise resolves successfully
            },
            error: 'error! please check your credentials.', // This message will be shown if the promise rejects with an error
          });
        }}>Sign Up</button>
      </form>
    </div>
  );
}

export default SignUp;
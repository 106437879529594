import React, { useContext, useEffect, useState } from 'react';
import './managestyles.css';
import ManageUsers from './ManageOrg/ManageUsers';
import { UserContext } from '../contexts/userContext';
import Loader from './Loader';
import Orgs from './SuperAdmin/Orgs';
import ManageOrgs from './SuperAdmin/ManageOrgs';
import { toast } from 'sonner';
import { deleteOrg } from '../api/orgApi';
import Header from './Header';

function ManageOrg() {

  const context = useContext(UserContext);

  const { user, handleLogout } = context;
  const [selectedOrg, setselectedOrg] = useState(null)

  useEffect(() => {
    setTimeout(() => {
      setIsLoader(false)
    }, 1000);
  }, [])

  const switchToManageOrg = async (orgSlug) => {
    try {
      setselectedOrg(orgSlug)
      setActiveTab(2)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const deleteOrgFunc = async (orgName) => {
    try {
      if (orgName) {
        const deleteOrgRes = await deleteOrg({ name: orgName })
        if (deleteOrgRes.status === 200 || deleteOrgRes.status === 201) {
          setActiveTab(1)
          return true;
        }
      }
    }
    catch (error) {
      return error;
    }
  }


  const [isLoader, setIsLoader] = useState(true)
  const [activeTab, setActiveTab] = useState(1);

  const [showMenu, setShowMenu] = useState(false);

  const userRole = {'user': "User", 'superuser': "Super Admin", 'admin': "Admin"}

  return (
    isLoader ? <Loader /> :
      <div className="container">
        <Header user={user} handleLogout={handleLogout} showMenu={showMenu} setShowMenu={setShowMenu} title={userRole[user?.userRole]} />
        {user?.userRole !== 'admin' && user?.userRole !== 'superuser' ?
          <div className='error-message'>
            <h1>You are not authorized to view this page</h1>
          </div>

          :

          <div>
            {user?.userRole === 'admin' ?
              <div className="content">
                <div className="sidebar" style={{ textAlign: "center" }}>
                  {/* <div onClick={() => setActiveTab(1)} className={`tab ${activeTab === 1 ? "active" : ""}`}>Add User</div> */}
                  <div className={`tab active`}>Manage Users</div>
                </div>
                <div className="main">
                  {/* {activeTab === 1 && <InviteUser />} */}
                  <ManageUsers />
                </div>
              </div> :

              <div className="content">
                <div className="sidebar" style={{ textAlign: "center" }}>
                  <div onClick={() => setActiveTab(1)} className={`tab ${activeTab === 1 ? "active" : ""}`}>Organizations</div>
                  <div onClick={() => setActiveTab(2)} className={`tab ${activeTab === 2 ? "active" : ""}`}>Manage Org</div>
                </div>
                <div className="main">
                  {activeTab === 1 && <Orgs switchToManageOrg={switchToManageOrg} />}
                  {activeTab === 2 && <ManageOrgs orgSlug={selectedOrg} deleteOrgFunc={deleteOrgFunc} />}
                </div>
              </div>}

          </div>}
      </div>
  );

}

export default ManageOrg;
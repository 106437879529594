import React, { useEffect, useState } from 'react'
import { createOrg, getAllOrgs } from '../../api/orgApi'
import ComponentLoader from '../ComponentLoader'
import { Toaster, toast } from 'sonner';
import Modal from 'react-modal';

const Orgs = ({ switchToManageOrg }) => {
    const [AllOrgs, setAllOrgs] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [isModal, setisModal] = useState(false)
    const [OrgName, setOrgName] = useState('')

    useEffect(() => {
        getAllOrgsFunction()
    }, [])

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    const createOrgFunc = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                const orgData = {
                    name: OrgName,
                }
                // Call handleLogin function and wait for its result
                const isSuccess = await createOrg(orgData);

                if (isSuccess.status === 200 || isSuccess.status === 201) {
                    resolve('Created successfully');
                    getAllOrgsFunction()
                } else {
                    reject(new Error(isSuccess.statusText));
                }
            } catch (error) {
                reject(error);
            }
        });
    }

    const getAllOrgsFunction = async () => {
        try {
            const allOrgs = await getAllOrgs()
            if (allOrgs.status === 200 || allOrgs.status === 201) {
                setAllOrgs(allOrgs.data.data)
                setIsLoading(false)
            }
        } catch (error) {
            setIsLoading(false)
            toast.error(error.message)
        }
    }

    return (
        isLoading ? <ComponentLoader /> :
            <div className="container">
                <Toaster position="top-right" richColors />
                <div className='mt-1'>
                    <button onClick={() => setisModal(true)} className='btn btn-primary btn-md mb-3'>Create New Org</button>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>Org Name</th>
                            <th>No of Users</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>

                        {AllOrgs && AllOrgs.length && AllOrgs.map(org => (
                            <tr key={org?.uid}>
                                <td>{org?.name}</td>
                                <td>{org?.userCount}</td>
                                <td><button className='btn btn-primary btn-md' onClick={async (e) => {
                                    e.preventDefault()
                                    switchToManageOrg(org?.orgSlug)
                                }}>Manage Org</button></td>
                            </tr>
                        ))}

                    </tbody>
                </table>
                <Modal
                    isOpen={isModal}
                    onAfterOpen={() => console.log('onAfterOpen')}
                    onRequestClose={() => setisModal(false)}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div style={{ width: '500px', padding: '80px' }}>
                        <div className='d-flex justify-content-around align-items-center mb-3'>
                            <h2>Create New Org</h2>
                        </div>
                        <div className="form-group mb-3">
                            <input value={OrgName} onChange={(e) => setOrgName(e.target.value)} type="email" className=" form-control" placeholder="OrgName" />
                        </div>

                        <div className='d-flex justify-content-around align-items-center'>
                        <button className='btn me-2 mt-2 w-100 btn-primary btn-md' onClick={async (e) => {
                            e.preventDefault()
                            // Create a wrapper function to call your promise function
                            const fetchSonner = () => {
                                return createOrgFunc()
                            };

                            // Use the wrapper function with toast.promise
                            toast.promise(fetchSonner, {
                                loading: 'Loading...', // This message will be shown while waiting for the promise to resolve
                                success: () => {
                                    setisModal(false)
                                    return `successfully created org`; // This message will be shown when the promise resolves successfully
                                },
                                error: (e) => {
                                    if (e.response.data.message.message) {
                                        return e.response.data.message.message;
                                    } else if (e.response.data.message) {
                                        return e.response.data.message
                                    } else {
                                        return 'error while creating org' // This message will be shown if the promise rejects with an error
                                    }
                                },
                            });
                        }} >Create</button>
                        <button className='btn mt-2 w-100 btn-primary btn-md' onClick={() => setisModal(false)}>Cancel</button>
                        </div>
                    </div>
                </Modal>
            </div>
    )
}

export default Orgs
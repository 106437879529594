import React from 'react'
import { useNavigate } from 'react-router-dom';

const Header = ({ user, handleLogout, showMenu, setShowMenu, title }) => {

    const navigate = useNavigate();
    return (
        <header className="header">
            <h1 onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>{title}</h1>
            <div className="user-info">

                <div className="user-dropdown" onClick={() => setShowMenu(!showMenu)}>
                    <div className=' fw-bold'>{user?.displayName ? user?.displayName : ''}</div>
                    <div>{user?.orgSlug && user?.orgSlug.split('.')[1]}</div>
                </div>

                {showMenu && (
                    <div className="user-menu" style={{ zIndex: 1000 }}>
                        <div className="user-heading">{user?.displayName ? user?.displayName : ''}</div>
                        {window.location.pathname !== "/" ? (
                            <div className="user-item" onClick={() => navigate('/')}>Home</div>
                        ) : ""}
                        <div onClick={() => navigate('/account-settings')} className="user-item">Account Settings</div>
                        <div className="user-item" onClick={() => {
                            handleLogout()
                            navigate('/')
                        }}>Logout</div>
                    </div>
                )}

            </div>
        </header>
    )
}

export default Header
import React, { useContext, useState, useRef } from 'react'
import '../styles.css';
import { UserContext } from '../../contexts/userContext';
import { Toaster, toast } from 'sonner';
import { changePass } from '../../api/userApi';
import ReactPasswordToggleIcon from 'react-password-toggle-icon';

export const ChangePassword = () => {
    const context = useContext(UserContext);
    let inputRef = useRef();
    let inputRef2 = useRef();
    let inputRef3 = useRef();
    const showIcon = () => <i class="fa fa-eye" aria-hidden="true"></i>;
    const hideIcon = () => <i class="fa fa-eye-slash" aria-hidden="true"></i>

    const { user, getUserDataFunction } = context;

    const [oldPass, setOldPass] = useState("");
    const [newPass, setNewPass] = useState("");
    const [confirmNewPass, setConfirmNewPass] = useState("");

    const changePassFunc = () => {
        return new Promise(async (resolve, reject) => {
            if (newPass !== confirmNewPass) {
                reject(new Error("Passwords do not match"))
            }
            if (newPass === oldPass) {
                reject(new Error("Passwords cannot be same!"))
            }
            try {

                // Call handleLogin function and wait for its result
                const isSuccess = await changePass({
                    oldPassword: oldPass,
                    newPassword: newPass
                });

                if (isSuccess.status === 200 || isSuccess.status === 201) {
                    resolve('Password Changed successfully'); // Resolve with a success message
                } else {
                    reject(new Error(isSuccess)); // Reject with an error if login fails
                }
            } catch (error) {
                reject(error); // Reject with the caught error if any error occurs
            }
        });
    }

    return (
        <div className="container d-flex  min-vh-100 w-100">
            <Toaster position="top-right" richColors />
            <div className='d-flex mx-auto mt-5 height-adjusted text-center
         flex-column' style={{ width: '50vw' }}>
                <h3 className="mb-3">{`Change Password`}</h3>
                <div className="form-group mb-3">
                    <div className="col" style={{ position: "relative", display: "block" }}>
                        <input ref={inputRef} type="password"
                            className="form-control"
                            value={oldPass}
                            placeholder="Current Password"
                            onChange={(e) => setOldPass(e.target.value)} />
                        <ReactPasswordToggleIcon
                            inputRef={inputRef}
                            showIcon={showIcon}
                            hideIcon={hideIcon}
                        />
                    </div>
                </div>

                <div className="form-group mb-3">
                    <div className="col " style={{ position: "relative", display: "block" }}>
                        <input ref={inputRef2} type="password"
                            className="form-control"
                            value={newPass}
                            placeholder="New Password"
                            onChange={(e) => setNewPass(e.target.value)} />
                        <ReactPasswordToggleIcon
                            inputRef={inputRef2}
                            showIcon={showIcon}
                            hideIcon={hideIcon}
                        />
                    </div>

                </div>
                <div className="form-group mb-3">
                    <div className="col " style={{ position: "relative", display: "block" }}>
                        <input ref={inputRef3} type="password"
                            className="form-control"
                            value={confirmNewPass}
                            placeholder="Confirm Password"
                            onChange={(e) => setConfirmNewPass(e.target.value)} />
                        <ReactPasswordToggleIcon
                            inputRef={inputRef3}
                            showIcon={showIcon}
                            hideIcon={hideIcon}
                        />
                    </div>
                </div>
                <div>
                    <button
                        className="btn btn-primary btn-lg"
                        onClick={async (e) => {
                            e.preventDefault();
                            // Create a wrapper function to call your promise function
                            const fetchSonner = () => {
                                return changePassFunc()
                            };

                            // Use the wrapper function with toast.promise
                            toast.promise(fetchSonner, {
                                loading: "Loading...", // This message will be shown while waiting for the promise to resolve
                                success: () => {
                                    getUserDataFunction()
                                    return `password changed successfully!`; // This message will be shown when the promise resolves successfully
                                },
                                error: (e) => {
                                    if (e?.response?.data?.message) {
                                        return e?.response?.data?.message;
                                    } else if (e?.message) {
                                        return e?.message;
                                    } else {
                                        return "error! please check your credentials.";
                                    }
                                }, // This message will be shown if the promise rejects with an error
                            });
                        }}
                    >
                        Change Password
                    </button>
                </div>
            </div>
        </div>
    );
};

import React from 'react'

const ComponentLoader = () => {
  return (
    <div className='loader loader-component'>
        <div className='loader-icon'></div>
    </div>
  )
}

export default ComponentLoader
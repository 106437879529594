import React, { useState } from 'react'
import './styles.css';
import { Toaster, toast } from 'sonner';
import { forgetPassword } from '../api/userApi';

function ForgetPass() {

    const [email, setEmail] = useState("")

    const forgetPassFunction = () => {
        return new Promise(async (resolve, reject) => {
            try {
                // Call handleLogin function and wait for its result
                const isSuccess = await forgetPassword({
                    email: email
                });

                if (isSuccess.status === 200 || isSuccess.status === 201) {
                    resolve('Email sent successfully!'); // Resolve with a success message
                } else {
                    reject(new Error(isSuccess)); // Reject with an error if login fails
                }
            } catch (error) {
                reject(error); // Reject with the caught error if any error occurs
            }
        });
    }

    return (
        <div className="container d-flex align-items-center justify-content-center min-vh-100 text-center w-100">
            <Toaster position="top-right" richColors />
            <form className=''>
                <h2 className='mb-3'>Forgot Password</h2>
                <div className="form-group mb-3">
                    <input type="email" className=" form-control" value={email} placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                </div>

                <button className='btn btn-primary btn-lg' onClick={async (e) => {
                    e.preventDefault()
                    // Create a wrapper function to call your promise function
                    const fetchSonner = () => {
                        return forgetPassFunction()
                    };

                    // Use the wrapper function with toast.promise
                    toast.promise(fetchSonner, {
                        loading: 'Loading...', // This message will be shown while waiting for the promise to resolve
                        success: () => {
                            return `reset password email successfully sent!`; // This message will be shown when the promise resolves successfully
                        },
                        error: (e) => {
                            if (e?.response?.data?.details?.length) {
                                return e?.response?.data?.details[0]?.message
                            }
                            if (e?.response?.data?.message) {
                                return e?.response?.data?.message?.code
                            }
                            return 'error! please check your credentials.'
                        }, // This message will be shown if the promise rejects with an error
                    });
                }}>Reset</button>
            </form>
        </div>
    )
}

export default ForgetPass
/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useState } from 'react';
import { loginIn, getUserData, refreshUser } from '../api/userApi';
export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  let [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const [Loading, setLoading] = useState(false);

  const handleLogin = async (loginData) => {
    try {

      const response = await loginIn(loginData);
      if (response.status === 200) {
        const { refreshToken, accessToken } = response.data;

        // Save accessToken to localStorage
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);

        // Set tokens to state
        setAccessToken(accessToken);
        setRefreshToken(refreshToken);

        await getUserDataFunction()
        return true;

      }
    } catch (error) {
      setLoading(false);
      console.error('Login Error:', error);
      return error;
    }
  };
  const handleRefresh = async () => {
    try {
      setLoading(true);

      const refreshTokenMain = localStorage.getItem("refreshToken");
      if (refreshTokenMain && refreshTokenMain !== undefined) {
        const response = await refreshUser({ "refreshToken": refreshTokenMain });
        if (response.status === 200) {
          const { refresh_token, access_token } = response.data;

          // Save accessToken to localStorage
          localStorage.setItem("accessToken", access_token);
          localStorage.setItem("refreshToken", refresh_token);

          // Set tokens to state
          setAccessToken(access_token);
          setRefreshToken(refresh_token);

          await getUserDataFunction()
          setLoading(false);

          return true;
        }
      }
    } catch (error) {
      setLoading(false);

      console.error('Login Error:', error);
      return error;
    }
  };
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    const expiration = getExpirationTime();
    if ((accessToken && refreshToken) && (accessToken !== undefined && refreshToken !== undefined) && expiration > 0) {
      setLoading(true);
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
      getUserDataFunction()
      setLoading(false);
    }
  }, [])

  function getExpirationTime() {
    const exp = Number(localStorage.getItem("exp"));
    const now = Math.floor(Date.now() / 1000);
    return exp - now;
  }

  useEffect(() => {
    // Function to check expiration and refresh token if necessary
    const checkExpirationAndRefresh = () => {
      const expiration = getExpirationTime();
      const refreshToken = localStorage.getItem("refreshToken");
      if (expiration < 300 && refreshToken) {
        handleRefresh();
      }
    };

    // Call the function initially
    checkExpirationAndRefresh();

    // Set interval to call the function every minute
    const intervalId = setInterval(checkExpirationAndRefresh, 60 * 1000); // 60 seconds * 1000 milliseconds

    // Clean up function to clear the interval when component unmounts
    return () => clearInterval(intervalId);
  }, []);
  
  const getUserDataFunction = async () => {
    // Call getUserData API after successful login
    const userDataResponse = await getUserData(); // Assuming getUserData returns user data
    if (userDataResponse.status === 200) {
      const userData = userDataResponse.data.data;
      user = userData;
      setUser(userData); // Set user data based on the received data
      localStorage.setItem("exp", userData.exp); // Set expiration time to localStorage
    }
  }

  const handleLogout = () => {
    // Rest of logout logic
    setUser(null);
    setAccessToken(null);
    setRefreshToken(null);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("exp");
  };

  return (
    <UserContext.Provider value={{ user, accessToken, refreshToken, handleLogin, handleLogout, setLoading, Loading, getUserDataFunction }}>
      {children}
    </UserContext.Provider>
  );
};

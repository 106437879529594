import axios from "axios";
import { BASE_URL } from "./config";

const apiInstance = axios.create({
  baseURL: BASE_URL,
});

// Add a request interceptor to set the access token
apiInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken"); // Assuming you store accessToken in localStorage
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const getUserData = (userId) => {
  return apiInstance.get(`/userData/`);
};

export const signup = (userData) => {
  return apiInstance.post("/signup/", userData);
};

export const loginIn = (loginData) => {
  return apiInstance.post("/login/", loginData);
};

export const refreshUser = (refreshData) => {
  return apiInstance.post("/refresh/", refreshData);
};

export const forgetPassword = (resetData) => {
  return apiInstance.post("/reset/", resetData);
};

export const userRoleChange = (changeData) => {
  return apiInstance.post("/users/updateRole", changeData);
};

export const createUser = (userData) => {
  return apiInstance.post("/createUser/", userData);
};

export const deleteUser = (userData) => {
  return apiInstance.delete("/users/delete/", { data: userData });
};

export const changePass = (passData) => {
  return apiInstance.post("/changePass/", passData);
};
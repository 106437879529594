import axios from "axios";
import { BASE_URL } from "./config";

const apiInstance = axios.create({
  baseURL: `${BASE_URL}/org`,
});

// Add a request interceptor to set the access token
apiInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken"); // Assuming you store accessToken in localStorage
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const getAllUsersInOrg = (orgData = null) => {
  if (orgData) {
    return apiInstance.post("/getAllUsers/", orgData);
  } else {
    return apiInstance.post("/getAllUsers/");
  }
};

export const getAllOrgs = () => {
  return apiInstance.get("/allOrgs/");
};

export const removeUserFromOrg = (userId) => {
  return apiInstance.post(`/removeUser/${userId}/`);
};

export const createOrg = (orgData) => {
  return apiInstance.post(`/`, orgData);
};

export const addUserToOrg = (userData) => {
  return apiInstance.post(`/addUser/`, userData);
};

export const switchOrgStatus = (orgSlug) => {
  return apiInstance.get(`/switchStatus/${orgSlug}/`);
};

export const deleteOrg = (orgData) => {
  return apiInstance.delete(`/`, { data: orgData });
};

export const getOrgData = (orgSlug) => {
  return apiInstance.get(`/${orgSlug}/`);
};